import {mapGetters} from "vuex";

export const companyPermissions = {

  computed: {
    ...mapGetters(['hasCompanyPermissions']),


    hasChatPermissions() {
      return this.hasCompanyPermissions('chat');
    },

    hasChatMultiUserGroupPermissions() {
      return this.hasChatPermissions && this.hasCompanyPermissions('chat.multiUserGroup');
    },

    hasChatVideoMeetsPermissions() {
      return this.hasChatPermissions && this.hasCompanyPermissions('chat.videoMeets');
    },


    hasCRMPermissions() {
      return this.hasCompanyPermissions('crm');
    },

    hasCRMFinancingWidgetPermissions() {
      return this.hasCRMPermissions && this.hasCompanyPermissions('crm.financingWidget');
    },

    hasCRMLeadExportPermissions() {
      return this.hasCRMPermissions && this.hasCompanyPermissions('crm.leadExport');
    },

    hasCRMLeadImportPermissions() {
      return this.hasCRMPermissions && this.hasCompanyPermissions('crm.leadImport');
    },


    hasAppraisalsPermissions() {
      return this.hasCompanyPermissions('appraisals');
    },


    hasCrossSellPermissions() {
      return this.hasCompanyPermissions('crossSell');
    },


    hasInventoryPermissions() {
      return this.hasCompanyPermissions('inventory');
    },

    hasInventoryCarPhotoFramesPermissions() {
      return this.hasInventoryPermissions && this.hasCompanyPermissions('inventory.carPhotoFrames');
    },

    hasFeedExportPermissions() {
      return this.hasInventoryPermissions && this.hasCompanyPermissions('inventory.feedExport');
    },

    hasFeedImportPermissions() {
      return this.hasInventoryPermissions && this.hasCompanyPermissions('inventory.feedImport');
    },


    hasCallCenterPermissions() {
      return this.hasCompanyPermissions('callCenter');
    },

    hasCallCenterNumberLookupPermissions() {
      return this.hasCallCenterPermissions && this.hasCompanyPermissions('callCenter.numberLookup');
    },


    hasCampaignPermissions() {
      return this.hasCompanyPermissions('campaigns');
    },

    hasCampaignSmsPermissions() {
      return this.hasCampaignPermissions && this.hasCompanyPermissions('campaigns.smsCampaigns');
    },

    hasCampaignEmailPermissions() {
      return this.hasCampaignPermissions && this.hasCompanyPermissions('campaigns.emailCampaigns');
    },

    hasCampaignVoicePermissions() {
      return this.hasCampaignPermissions && this.hasCompanyPermissions('campaigns.voicemailCampaigns');
    },


    hasWebMailPermissions() {
      return this.hasCompanyPermissions('webMail');
    },


    hasInternalChatPermissions() {
      return this.hasCompanyPermissions('internalChat');
    },

    hasInternalChatGroupPermissions() {
      return this.hasInternalChatPermissions && this.hasCompanyPermissions('internalChat.multiUserGroup');
    },


    hasReportsPermissions() {
      return this.hasCompanyPermissions('reports');
    },


    hasFtpPermissions() {
      return this.hasCompanyPermissions('ftp');
    },


    hasListingsPermissions() {
      return this.hasCompanyPermissions('listings');
    },


    hasContestsPermissions() {
      return this.hasCompanyPermissions('contests');
    },


    hasAffiliatesPermissions() {
      return this.hasCompanyPermissions('affiliates');
    },


    hasWorkPlanPermissions() {
      return this.hasCompanyPermissions('workPlan');
    },


    hasCommunicationPermissions() {
      return this.hasCompanyPermissions('communication');
    },

    hasCommunicationAutomationsPermissions() {
      return this.hasCommunicationPermissions && this.hasCompanyPermissions('communication.automations');
    },

    hasCommunicationActionPlansPermissions() {
      return this.hasCommunicationPermissions && this.hasCompanyPermissions('communication.actionPlans');
    },

    hasCommunicationChatBotPermissions() {
      return this.hasCommunicationPermissions && this.hasCompanyPermissions('communication.chatBot');
    },


    hasSecurityPermissions() {
      return this.hasCompanyPermissions('security');
    },

    hasSecurityFirewallPermissions() {
      return this.hasSecurityPermissions && this.hasCompanyPermissions('security.firewall');
    },


    hasIntegrationPermissions() {
      return this.hasCompanyPermissions('integration');
    },

    hasIntegrationFacebookPermissions() {
      return this.hasIntegrationPermissions && this.hasCompanyPermissions('integration.facebook');
    },

    hasIntegrationCarFaxPermissions() {
      return this.hasIntegrationPermissions && this.hasCompanyPermissions('integration.carfax');
    },

    hasIntegrationEzAutosBuyPermissions() {
      return this.hasIntegrationPermissions && this.hasCompanyPermissions('integration.ezautosbuy');
    }
  },

  watch: {
    hasChatPermissions(enabled) {
      if (!enabled && this.requireRouteAccess('chat')) {
        this.goToNoAccess();
      }
    },

    hasCRMPermissions(enabled) {
      if (!enabled && this.requireRouteAccess('crm')) {
        this.goToNoAccess();
      }
    },

    hasAppraisalsPermissions(enabled) {
      if (!enabled && this.requireRouteAccess('appraisals')) {
        this.goToNoAccess();
      }
    },

    hasCrossSellPermissions(enabled) {
      if (!enabled && this.requireRouteAccess('crossSell')) {
        this.goToNoAccess();
      }
    },

    hasInventoryPermissions(enabled) {
      if (!enabled && this.requireRouteAccess('inventory')) {
        this.goToNoAccess();
      }
    },

    hasCallCenterPermissions(enabled) {
      if (!enabled && this.requireRouteAccess('callCenter')) {
        this.goToNoAccess();
      }
    },

    hasCampaignPermissions(enabled) {
      if (!enabled && this.requireRouteAccess('campaigns')) {
        this.goToNoAccess();
      }
    },

    hasWebMailPermissions(enabled) {
      if (!enabled && this.requireRouteAccess('webMail')) {
        this.goToNoAccess();
      }
    },

    hasReportsPermissions(enabled) {
      if (!enabled && this.requireRouteAccess('reports')) {
        this.goToNoAccess();
      }
    },

    hasFtpPermissions(enabled) {
      if (!enabled && this.requireRouteAccess('ftp')) {
        this.goToNoAccess();
      }
    },

    hasListingsPermissions(enabled) {
      if (!enabled && this.requireRouteAccess('listings')) {
        this.goToNoAccess();
      }
    },

    hasContestsPermissions(enabled) {
      if (!enabled && this.requireRouteAccess('contests')) {
        this.goToNoAccess();
      }
    },

    hasAffiliatesPermissions(enabled) {
      if (!enabled && this.requireRouteAccess('affiliates')) {
        this.goToNoAccess();
      }
    },

    hasWorkPlanPermissions(enabled) {
      if (!enabled && this.requireRouteAccess('workPlan')) {
        this.goToNoAccess();
      }
    },

    hasCommunicationPermissions(enabled) {
      if (!enabled && this.requireRouteAccess('communication')) {
        this.goToNoAccess();
      }
    },

    hasSecurityPermissions(enabled) {
      if (!enabled && this.requireRouteAccess('security')) {
        this.goToNoAccess();
      }
    },

    hasIntegrationPermissions(enabled) {
      if (!enabled && this.requireRouteAccess('integration')) {
        this.goToNoAccess();
      }
    }
  },

  methods: {
    goToNoAccess() {
      this.$router.push({name: 'NoAccess', query: {module: this.$route.name}});
    },

    requireRouteAccess(module) {
      const {meta} = this.$route;
      return meta[module] || false;
    }
  }

};