import vLoadIndicator from '../directives/v-load-indicator';

var VueLoadIndicator = {
    install: (Vue) => {
        Vue.directive('load-indicator', vLoadIndicator);
    }
};

export default VueLoadIndicator;

if (typeof window !== 'undefined' && window.Vue) {
    window.Vue.use(VueLoadIndicator);
}
