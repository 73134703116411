import IService from './iservice';
import config from "@/config";
import utils from '@/helpers/utils';
import store from "@/store/store";

const server_base = config[config.stage].ws_server;

class AgentService extends IService {
  constructor(http) {
    super(http);
    this.store = store;
  }

  async create(agent) {
    try {
      let response = await this.http.post(`${ server_base }/api/agent`, { agent });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async SetPassword(id, Password, PasswordConfirm) {
    try {
      let response = await this.http.post(`${ server_base }/api/agent/set-password`,
          {
            id,
            Password,
            PasswordConfirm
          });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async list(limit, skip, filters = {}) {
    try {
      const query = {
        limit, skip
      };

      if ( filters?.company ) query.company = filters.company;
      if ( filters?.role ) query.role = filters.role;
      if ( filters?.search ) {
        query.search = utils.isUsPhone(filters.search)
            ? utils.NormalizeNumber(filters.search) : filters.search;
      }

      const response = await this.http.get(`${ server_base }/api/agent`, query);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async listByRole(role, limit, skip, sort) { // role is string comma separated, ex: "admin,bdc,seller"
    try {
      const query = {};

      if ( limit ) query.limit = limit;
      if ( skip ) query.skip = skip;
      if ( role ) query.role = role;
      if ( sort ) query.sort = sort;

      this.addLoading('list_by_role');
      const response = await this.http.get(`${ server_base }/api/agent/role`, query);
      this.delLoading('list_by_role');
      return response.data;
    } catch (e) {
      this.delLoading('list_by_role');
      return null;
    }
  }

  async agentsForInternalChat(loading = 'list_for_internal_chat') {
    try {
      this.addLoading(loading);
      const response = await this.http.get(`${ server_base }/api/agent/agents-for-internal-chat`);
      this.delLoading(loading);
      return response.data;
    } catch (e) {
      this.delLoading(loading);
      return null;
    }
  }


  async get(id) {
    try {
      let response = await this.http.get(`${ server_base }/api/agent/${ id }`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async profile() {
    try {
      let response = await this.http.get(`${ server_base }/api/agent/profile`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async editProfile(agent) {
    try {
      let response = await this.http.put(`${ server_base }/api/agent/profile`, { agent });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async edit(agent) {
    try {
      let response = await this.http.put(`${ server_base }/api/agent`, { agent });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async editAvatar(id, avatar) {
    try {
      let response = await this.http.put(`${ server_base }/api/agent/avatar`, { id, avatar });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async delete(id) {
    try {
      let response = await this.http.delete(`${ server_base }/api/agent/${ id }`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async ownAgents() {
    try {
      let response = await this.http.get(`${ server_base }/api/agent/own_agents`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async listSellers(date, filter) {
    try {
      let response = await this.http.get(`${ server_base }/api/agent/list-sellers?date=${ date }&filter=${ filter }`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async count(dateRange) {
    try {
      const response = await this.http.get(`${ server_base }/api/agent/statistic/count`, { dateRange });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async areaChart(dateView, agent = null) {
    try {
      const response = await this.http.get(`${ server_base }/api/agent/statistic/area-chart`, { dateView, agent });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async Impersonal(id, type = "in") {
    try {
      let response = await this.http.get(`${ server_base }/api/agent/impersonate/${ id }?type=${ type }`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async getLocation(user_id) {
    try {
      let response = await this.http.get(`${ server_base }/api/agent/${ user_id }/location`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async getBDCRanking() {
    try {
      let response = await this.http.get(`${ server_base }/api/agent/statistic/rank`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async getSellersRanking() {
    try {
      let response = await this.http.get(`${ server_base }/api/agent/statistic/rank?type=seller`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async transferCustomer(data) {
    try {
      let response = await this.http.put(`${ server_base }/api/agent/transfer-customers`, data);
      return response.data;
    } catch (e) {
      return null;
    }
  }
}

export default AgentService;