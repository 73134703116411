import vAutoScroll from '../directives/v-auto-scroll';

var VueAutoScroll = {
    install: (Vue) => {
        Vue.directive('auto-scroll', vAutoScroll)
    }
};

export default VueAutoScroll;

if (typeof window !== 'undefined' && window.Vue) {
    window.Vue.use(VueAutoScroll)
}
