const Geolocation = {
    install(Vue) {
        Vue.prototype.$getLocation = Geolocation.getLocation
    },

    getLocation(options = {}) {
        return new Promise((resolve, reject) => {
            if (!Geolocation._isAvailable()) {
                reject('no browser support')
            } else {
                window.navigator.geolocation.getCurrentPosition(
                    position => {
                        resolve({
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                            altitude: position.coords.altitude,
                            altitudeAccuracy: position.coords.altitudeAccuracy,
                            accuracy: position.coords.accuracy
                        })
                    },
                    () => {
                        reject('no position access')
                    },
                    options
                )
            }
        })
    },

    _isAvailable() {
        return 'geolocation' in window.navigator;
    }
}

export default Geolocation;