import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store/store';

import LegalPoliciesRoutes from '@/views/LegalPolicies/routes';
import NoAccessRoutes from "@/views/NoAccess/route";
import CampaignsRoutes from '@/views/Campaigns/routes';
import ChatRoutes from "@/views/Chat/routes";
import ChatBotRoutes from "@/views/ChatBot/routes";
import PromotionsRoutes from '@/views/Promotions/routes';
import WidgetsRoutes from "@/views/Widgets/routes";
import CallCenterRoutes from '@/views/CallCenter/routes';
import CRMRoutes from '@/views/CRM/routes';
import ListingRoutes from '@/views/Listings/routes';
import FinanceApplicationRoutes from '@/views/FinanceApplications/routes';
import InventoryRoutes from '@/views/Inventory/routes';
import AccountingRoutes from '@/views/Accounting/routes';
import SettingsRoutes from '@/views/Settings/routes';
import CompaniesRoutes from '@/views/Companies/routes';
import ShowroomRoutes from "@/views/Showroom/routes";
import SupportRoutes from "@/views/Support/routes";
import CrossSellRoutes from "@/views/CrossSell/routes";
import WorkplanRoutes from "@/views/Workplan/routes";
import LinkTreeRoutes from "@/views/LinkTree/routes";
import AffiliatesRoutes from "@/views/Affiliates/routes";
import AppraisalsRoutes from "@/views/Appraisals/routes";
import LegalDocumentsRoutes from "@/views/LegalDocuments/routes";
import FtpAccountsRoutes from "@/views/Ftp/routes";
import ReportingRoutes from "@/views/Reporting/routes";

Vue.use(Router);

const router = new Router({
  mode: 'history',

  base: process.env.BASE_URL,

  routes: [
    {
      path: '/policies',
      name: 'PoliciesOverview',
      component: () => import(/* webpackChunkName: "policies_overview" */ '@/views/Layouts/Policies/index.vue'),
      meta: {
        requiresAuth: false,
        title: 'policies_title'
      },
      children: [
        ...LegalPoliciesRoutes,
      ]
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import(/* webpackChunkName: "login" */ '@/views/Login/index.vue'),
      meta: {
        guest: true,
        login: true,
        title: 'login_title'
      }
    },
    {
      path: '/billing/activation',
      name: 'ActivationPayment',
      component: () => import(/* webpackChunkName: "activation_payment" */ '@/views/Settings/Billing/Payment/ActivationPayment.vue'),
      meta: {
        requiresAuth: true,
        title: 'billing_pay_now_title'
      }
    },
    {
      path: '/dashboard',
      component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Layouts/Dashboard/index.vue'),
      meta: {
        requiresAuth: true,
        user: true,
        title: 'home_title'
      },
      children: [
        {
          path: '',
          name: 'DashboardIndex',
          component: () => import(/* webpackChunkName: "home" */ '@/views/Home/Home.vue'),
          meta: {
            requiresAuth: true,
            user: true,
            title: 'home_title'
          }
        },
        {
          path: 'profile',
          name: 'Profile',
          component: () => import(/* webpackChunkName: "my_profile" */ '@/views/MyProfile/index.vue'),
          meta: {
            requiresAuth: true,
            user: true,
            title: 'profile_title'
          }
        },
        {
          path: 'notifications',
          name: 'Notifications',
          component: () => import(/* webpackChunkName: "notifications_center" */ '@/views/NotificationsCenter/index.vue'),
          meta: {
            requiresAuth: true,
            user: true,
            title: 'notifications_title'
          }
        },
        {
          path: 'daily-log',
          name: 'DailyLog',
          component: () => import(/* webpackChunkName: "daily_log" */ '@/views/DailyLog/index.vue'),
          meta: {
            requiresAuth: true,
            agent: true,
            title: 'daily_log_title'
          }
        },
        {
          path: 'mail',
          name: 'Mail',
          component: () => import(/* webpackChunkName: "mail" */ '@/views/Mail/index.vue'),
          meta: {
            requiresAuth: true,
            agent: true,
            title: 'mail_title'
          }
        },
        {
          path: 'logs',
          name: 'Logs',
          component: () => import(/* webpackChunkName: "logs" */ '@/views/Logs/index.vue'),
          meta: {
            requiresAuth: true,
            admin: true,
            title: 'logs_title'
          }
        },
        {
          path: 'migrations',
          name: 'Migrations',
          component: () => import(/* webpackChunkName: "migrations" */ '@/views/Migrations/index.vue'),
          meta: {
            requiresAuth: true,
            admin: true,
            title: 'migrations_title'
          }
        },
        {
          path: 'leaderboard',
          name: 'LeaderBoard',
          component: () => import(/* webpackChunkName: "leader_board" */ '@/views/LeaderBoard/index.vue'),
          meta: {
            requiresAuth: true,
            agent: true,
            title: 'leaderboard_title'
          }
        },
        {
          path: 'contest',
          name: 'Contest',
          component: () => import(/* webpackChunkName: "contests" */ '@/views/Contest/index.vue'),
          meta: {
            requiresAuth: true,
            admin: true,
            title: 'contest_title'
          }
        },

        ...NoAccessRoutes,
        ...CampaignsRoutes,
        ...ChatRoutes,
        ...ChatBotRoutes,
        ...PromotionsRoutes,
        ...WidgetsRoutes,
        ...CallCenterRoutes,
        ...CRMRoutes,
        ...ListingRoutes,
        ...FinanceApplicationRoutes,
        ...InventoryRoutes,
        ...AccountingRoutes,
        ...SettingsRoutes,
        ...CompaniesRoutes,
        ...ShowroomRoutes,
        ...SupportRoutes,
        ...CrossSellRoutes,
        ...WorkplanRoutes,
        ...LinkTreeRoutes,
        ...AffiliatesRoutes,
        ...AppraisalsRoutes,
        ...LegalDocumentsRoutes,
        ...FtpAccountsRoutes,
        ...ReportingRoutes,
      ]
    },
    {
      path: '*',
      redirect: '/dashboard',
      meta: {
        requiresAuth: true,
        user: true,
        title: 'home_title'
      }
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  if ( to?.meta?.title ) {
    store.commit('SET_ROUTE_TITLE', to.meta.title);
  }

  const gotoLogin = async () => {
    await store.dispatch("logout");
    return next({ name: 'Login', params: { nextUrl: to.fullPath } });
  };

  if ( to.matched.some(record => record.meta.requiresAuth) ) {
    const storeUser = store.getters.user
        , token = localStorage.getItem('token')
        , expires = localStorage.getItem('expires');

    if ( storeUser && token !== null && expires > (new Date()).getTime().toString() ) {
      const roles = storeUser.permissions;
      const permissions = storeUser.Company?.permissions;

      if ( // Handle user permissions
          to.matched.some(record => record.meta.root && !roles.isRoot) ||
          to.matched.some(record => record.meta.support && !roles.isSupport) ||
          to.matched.some(record => record.meta.reseller && !roles.isReSeller) ||
          to.matched.some(record => record.meta.admin && !roles.isAdmin) ||
          to.matched.some(record => record.meta.bdc && !roles.isBDC) ||
          to.matched.some(record => record.meta.inventory && !roles.isInventory) ||
          to.matched.some(record => record.meta.manager && !roles.isManager) ||
          to.matched.some(record => record.meta.agent && !roles.isAgent) ||
          to.matched.some(record => record.meta.accountant && !roles.isAccountant) ||
          to.matched.some(record => record.meta.reception && !roles.isReception) ||
          to.matched.some(record => record.meta.user && !roles.isUser)
      ) {
        return gotoLogin();

      } else if ( // Handle company permissions
          to.matched.some(record => record.meta.chat && !permissions.chat.enabled) ||
          to.matched.some(record => record.meta.crm && !permissions.crm.enabled) ||
          to.matched.some(record => record.meta.appraisals && !permissions.appraisals.enabled) ||
          to.matched.some(record => record.meta.crossSell && !permissions.crossSell.enabled) ||
          to.matched.some(record => record.meta.inventory && !permissions.inventory.enabled) ||
          to.matched.some(record => record.meta.callCenter && !permissions.callCenter.enabled) ||
          to.matched.some(record => record.meta.campaigns && !permissions.campaigns.enabled) ||
          to.matched.some(record => record.meta.internalChat && !permissions.internalChat.enabled) ||
          to.matched.some(record => record.meta.webMail && !permissions.webMail.enabled) ||
          to.matched.some(record => record.meta.reports && !permissions.reports.enabled) ||
          to.matched.some(record => record.meta.ftp && !permissions.ftp.enabled) ||
          to.matched.some(record => record.meta.listings && !permissions.listings.enabled) ||
          to.matched.some(record => record.meta.contests && !permissions.contests.enabled) ||
          to.matched.some(record => record.meta.affiliates && !permissions.affiliates.enabled) ||
          to.matched.some(record => record.meta.workPlan && !permissions.workPlan.enabled) ||
          to.matched.some(record => record.meta.communication && !permissions.communication.enabled) ||
          to.matched.some(record => record.meta.security && !permissions.security.enabled) ||
          to.matched.some(record => record.meta.integration && !permissions.integration.enabled)
      ) {
        return next({ name: 'NoAccess', query: { module: to.name } });

      } else {
        return next();
      }

    } else {
      return gotoLogin();
    }

  } else {
    return next();
  }
});

export default router;