export default {
  "en": {
    "title": 'EZLeads Dashboard',


      "button": {
          "add": "Add",
          "back": "Back",
          "cancel": "Cancel",
          "delete": "Delete",
          "details": "Nav",
          "edit": "Edit",
          "save": "Save",
          "show": "Show",
      },
      "label": {

      },

      "placeholder": {

      },

      "info": {

      },


      "error": {
          "owner_already_added": "Agent already added",
          "owner_already_added_description": "The agent you are trying to set as owner is added in list",
          "agent_is_owner": "Agent is owner",
          "agent_is_owner_description": "The agent you are trying to add is the owner of the group",
          "agent_already_added": "Agent already added",
          "agent_already_added_description": "The agent you are trying to add is already added in list",
          "owner_empty": "Empty Owner",
          "owner_empty_description" : "You must provide a valid owner for this group",
          "agents_empty": "Empty Group",
          "agents_empty_description": "You must provide at least one agent to the group",
          "create_group": "Error creating the group",
          "create_group_description" : "There was and error creating the group, please try again later",
      }
  },
  "es": {
    "title": 'EZLeads Administración'
  }
};
